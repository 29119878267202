import React, { useState, useEffect, useRef } from 'react';
import './Landingpage.css';
import Navigation from './Navigation';
import Hero from './Hero';
import Services from './Services';
import Features from './Features';
import About from './About';
import Testimonials from './Testimonials';
import ContactUs from './ContactUs';
import Footer from './Footer';
import Header from './Header';



// Header Component


// Main LandingPage Component
const LandingPage = () => {
  return (
    <div className="landing-page">
      <Header/>
      <main>
        <Hero/>
        <Services/>
        <Features />
        <About />
        <Testimonials/> 
        <ContactUs/>
        <Footer/>




        
      </main>
      
    </div>
  );
};

export default LandingPage;