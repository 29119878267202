import React from 'react';
import styles from './ProgressBar.module.css';

const ProgressBar = ({ current, total }) => {
  const progress = (current / total) * 100;

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
      <div className={styles.progressText}>{current} / {total}</div>
    </div>
  );
};

export default ProgressBar;