import React, { useState, useEffect, useRef } from 'react';
import styles from './Features.module.css';

const Features = () => {
  const [activeFeature, setActiveFeature] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const featuresRef = useRef(null);

  const features = [
    {
      id: 1,
      title: 'Advanced AI Integration',
      description: 'Harness the power of artificial intelligence to optimize your business processes and gain valuable insights.',
      icon: 'fas fa-brain',
      color: '#4e54c8',
    },
    {
      id: 2,
      title: 'Robust Cloud Infrastructure',
      description: 'Scale your applications effortlessly with our state-of-the-art cloud solutions and expert management.',
      icon: 'fas fa-cloud',
      color: '#00b4db',
    },
    {
      id: 3,
      title: 'Cutting-edge Cybersecurity',
      description: 'Protect your digital assets with our advanced security measures and proactive threat detection.',
      icon: 'fas fa-shield-alt',
      color: '#ff4b2b',
    },
    {
      id: 4,
      title: 'IoT Ecosystem',
      description: 'Connect and manage your devices seamlessly with our comprehensive Internet of Things solutions.',
      icon: 'fas fa-network-wired',
      color: '#00c9ff',
    },
    {
      id: 5,
      title: 'Blockchain Integration',
      description: 'Implement secure and transparent blockchain solutions to revolutionize your business operations.',
      icon: 'fas fa-link',
      color: '#8e2de2',
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (featuresRef.current) {
      observer.observe(featuresRef.current);
    }

    return () => {
      if (featuresRef.current) {
        observer.unobserve(featuresRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % features.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [features.length]);

  return (
    <section ref={featuresRef} className={`${styles.features} ${isVisible ? styles.visible : ''}`}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>Our Key Features</h2>
        <p className={styles.sectionSubtitle}>
          Discover the cutting-edge technologies that set Greytech Solutions apart
        </p>
        <div className={styles.featureShowcase}>
          <div className={styles.featureList}>
            {features.map((feature, index) => (
              <div
                key={feature.id}
                className={`${styles.featureItem} ${index === activeFeature ? styles.active : ''}`}
                onClick={() => setActiveFeature(index)}
              >
                <div className={styles.featureIcon} style={{ backgroundColor: feature.color }}>
                  <i className={feature.icon}></i>
                </div>
                <div className={styles.featureContent}>
                  <h3 className={styles.featureTitle}>{feature.title}</h3>
                  <p className={styles.featureDescription}>{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.featureVisual}>
            <div className={styles.visualContainer}>
              {features.map((feature, index) => (
                <div
                  key={feature.id}
                  className={`${styles.visualItem} ${index === activeFeature ? styles.active : ''}`}
                  style={{ backgroundColor: feature.color }}
                >
                  <i className={`${feature.icon} ${styles.largeIcon}`}></i>
                  <div className={styles.particles}>
                    {[...Array(20)].map((_, i) => (
                      <div key={i} className={styles.particle}></div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.backgroundDecoration}>
        <div className={styles.circle}></div>
        <div className={styles.square}></div>
        <div className={styles.triangle}></div>
      </div>
    </section>
  );
};

export default Features;