import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './TestSeries.module.css';
import Timer from './Timer';
import ProgressBar from './ProgressBar';
import TestResults from './TestResults';

const questions = [
  {
    id: 1,
    question: "What is the capital of France?",
    options: ["London", "Berlin", "Paris", "Madrid"],
    correctAnswer: "Paris"
  },
  {
    id: 2,
    question: "Which planet is known as the Red Planet?",
    options: ["Venus", "Mars", "Jupiter", "Saturn"],
    correctAnswer: "Mars"
  },
  {
    id: 3,
    question: "What is the largest planet in our solar system?",
    options: ["Earth", "Saturn", "Jupiter", "Uranus"],
    correctAnswer: "Jupiter"
  },
  {
    id: 4,
    question: "What is the smallest country in the world?",
    options: ["Vatican City", "Monaco", "Nauru", "Tuvalu"],
    correctAnswer: "Vatican City"
  },
  {
    id: 5,
    question: "What is the largest living species of lizard?",
    options: ["Komodo dragon", "Saltwater crocodile", "Black mamba", "Green anaconda"],
    correctAnswer: "Komodo dragon"
  },
  {
    id: 6,
    question: "What is the highest mountain peak in the solar system?",
    options: ["Mount Everest", "Olympus Mons", "Mauna Kea", "Denali"],
    correctAnswer: "Olympus Mons"
  },
  {
    id: 7,
    question: "What is the deepest part of the ocean?",
    options: ["Mariana Trench", "Challenger Deep", "Tonga Trench", "Kermadec Trench"],
    correctAnswer: "Challenger Deep"
  },
  {
    id: 8,
    question: "What is the longest river in South America?",
    options: ["Amazon River", "Parana River", "Sao Francisco River", "Magdalena River"],
    correctAnswer: "Amazon River"
  },
  {
    id: 9,
    question: "What is the largest desert in the world?",
    options: ["Sahara Desert", "Gobi Desert", "Mojave Desert", "Atacama Desert"],
    correctAnswer: "Sahara Desert"
  },
  {
    id: 10,
    question: "What is the world's largest waterfall, by volume of water?",
    options: ["Victoria Falls", "Iguazu Falls", "Niagara Falls", "Angel Falls"],
    correctAnswer: "Victoria Falls"
  }
];

const TestSeries = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [timeSpent, setTimeSpent] = useState({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [testStarted, setTestStarted] = useState(false);
  const [testCompleted, setTestCompleted] = useState(false);
  const [remainingTime, setRemainingTime] = useState(30 * 60); // 30 minutes
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const startTime = useRef(null);
  const navigate = useNavigate();

  const handleQuestionChange = (event) => {
    setCurrentQuestion(Number(event.target.value));
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement && testStarted && !testCompleted) {
        alert("Please return to full screen to continue the test.");
        document.documentElement.requestFullscreen().catch((err) => {
          console.error(`Error attempting to enable full-screen mode: ${err.message}`);
        });
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, [testStarted, testCompleted]);

  useEffect(() => {
    let timer;
    if (testStarted && !testCompleted) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer);
            setTestCompleted(true);
            setShowResults(true);
            return 0;
          }
          return prevTime - 1;
        });
        
        // Update time spent on current question
        setTimeSpent((prev) => ({
          ...prev,
          [currentQuestion]: (prev[currentQuestion] || 0) + 1
        }));
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [testStarted, testCompleted, currentQuestion]);

  const startTest = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen().then(() => {
        setTestStarted(true);
        startTime.current = Date.now();
      }).catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    } else {
      setTestStarted(true);
      startTime.current = Date.now();
    }
  };

  const handleAnswer = (answer) => {
    setAnswers(prev => ({ ...prev, [currentQuestion]: answer }));
  };

  const nextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(prev => prev + 1);
    } else {
      setTestCompleted(true);
      setShowResults(true);
    }
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(prev => prev - 1);
    }
  };

  const handleReviewMode = () => {
    setIsReviewMode(true);
    setCurrentQuestion(0);
  };

  const exitReviewMode = () => {
    setIsReviewMode(false);
    setTestCompleted(true);
    setShowResults(true);
  };

  if (!testStarted) {
    return (
      <div className={styles.startScreen}>
        <h1>Welcome to the Test Series</h1>
        <p>This test consists of 10 questions. You must complete the test in full screen mode.</p>
        <p>You have 30 minutes to complete the test.</p>
        <p><strong>Note: The test will start in full-screen mode. Please allow full-screen when prompted.</strong></p>
        <button onClick={startTest} className={styles.startButton}>Start Test</button>
      </div>
    );
  }

  if (showResults) {
    return (
      <TestResults 
        answers={answers} 
        timeSpent={timeSpent} 
        totalTime={30 * 60 - remainingTime}
        onReview={handleReviewMode}
        questions={questions}
      />
    );
  }

  const currentQuestionData = questions[currentQuestion];

  return (
    <div className={styles.testContainer}>
      <div className={styles.header}>
        <Timer remainingTime={remainingTime} />
        <ProgressBar current={currentQuestion + 1} total={questions.length} />
      </div>
      <div className={styles.questionCard}>
        <div className={styles.questionNavigation}>
          <h2>Question {currentQuestion + 1} of {questions.length}</h2>
          <select 
            value={currentQuestion} 
            onChange={handleQuestionChange}
            className={styles.questionSelect}
          >
            {questions.map((_, index) => (
              <option key={index} value={index}>
                Q{index + 1} {answers[index] ? '✓' : ''}
              </option>
            ))}
          </select>
        </div>
        <p className={styles.question}>{currentQuestionData.question}</p>
        <div className={styles.options}>
          {currentQuestionData.options.map((option, index) => (
            <button
              key={index}
              onClick={() => !isReviewMode && handleAnswer(option)}
              className={`${styles.optionButton} 
                ${answers[currentQuestion] === option ? styles.selected : ''}
                ${isReviewMode && option === currentQuestionData.correctAnswer ? styles.correct : ''}
                ${isReviewMode && answers[currentQuestion] === option && option !== currentQuestionData.correctAnswer ? styles.incorrect : ''}
              `}
              disabled={isReviewMode}
            >
              {option}
            </button>
          ))}
        </div>
        {isReviewMode && (
          <div className={styles.explanation}>
            <h3>Explanation:</h3>
            <p>{currentQuestionData.explanation}</p>
          </div>
        )}
        <div className={styles.navigation}>
          <button onClick={prevQuestion} disabled={currentQuestion === 0}>Previous</button>
          {currentQuestion === questions.length - 1 ? (
            <button onClick={nextQuestion}>Finish</button>
          ) : (
            <button onClick={nextQuestion}>Next</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestSeries;