import React, { useState } from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    // Simulating API call for newsletter subscription
    setTimeout(() => {
      setSubscribeSuccess(true);
      setEmail('');
    }, 1000);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <h3 className={styles.footerTitle}>About Greytech Solutions</h3>
          <p className={styles.footerText}>
            Greytech Solutions is a leading technology company specializing in innovative software solutions, cloud services, and digital transformation. We empower businesses to thrive in the digital age.
          </p>
          <div className={styles.socialIcons}>
            <a href="#" className={styles.socialIcon} aria-label="Facebook">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className={styles.socialIcon} aria-label="Twitter">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className={styles.socialIcon} aria-label="LinkedIn">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="#" className={styles.socialIcon} aria-label="Instagram">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className={styles.footerSection}>
          <h3 className={styles.footerTitle}>Quick Links</h3>
          <ul className={styles.footerLinks}>
            <li><a href="#" className={styles.footerLink}>Home</a></li>
            <li><a href="#" className={styles.footerLink}>About Us</a></li>
            <li><a href="#" className={styles.footerLink}>Services</a></li>
            <li><a href="#" className={styles.footerLink}>Portfolio</a></li>
            <li><a href="#" className={styles.footerLink}>Careers</a></li>
            <li><a href="#" className={styles.footerLink}>Contact</a></li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h3 className={styles.footerTitle}>Our Services</h3>
          <ul className={styles.footerLinks}>
            <li><a href="#" className={styles.footerLink}>Custom Software Development</a></li>
            <li><a href="#" className={styles.footerLink}>Cloud Solutions</a></li>
            <li><a href="#" className={styles.footerLink}>Cybersecurity</a></li>
            <li><a href="#" className={styles.footerLink}>AI & Machine Learning</a></li>
            <li><a href="#" className={styles.footerLink}>IoT Solutions</a></li>
            <li><a href="#" className={styles.footerLink}>Blockchain Development</a></li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h3 className={styles.footerTitle}>Newsletter</h3>
          <p className={styles.footerText}>
            Stay updated with our latest news and offers. Subscribe to our newsletter!
          </p>
          <form onSubmit={handleSubscribe} className={styles.newsletterForm}>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className={styles.newsletterInput}
              required
            />
            <button type="submit" className={styles.newsletterButton}>
              Subscribe
            </button>
          </form>
          {subscribeSuccess && (
            <p className={styles.subscribeSuccess}>Thank you for subscribing!</p>
          )}
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.footerBottomContent}>
          <p className={styles.copyright}>
            &copy; {new Date().getFullYear()} Greytech Solutions. All rights reserved.
          </p>
          <ul className={styles.legalLinks}>
            <li><a href="#" className={styles.legalLink}>Privacy Policy</a></li>
            <li><a href="#" className={styles.legalLink}>Terms of Service</a></li>
            <li><a href="#" className={styles.legalLink}>Cookie Policy</a></li>
          </ul>
        </div>
      </div>
      <div className={styles.footerDecoration}>
        <div className={styles.circle}></div>
        <div className={styles.square}></div>
        <div className={styles.triangle}></div>
      </div>
    </footer>
  );
};

export default Footer;