import React from 'react';
import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage'; // Ensure the filename is 'LandingPage.js'
import TestSeries from './components/TestSeries';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/test" element={<TestSeries />} />
        
      </Routes>
    </Router>
  );
}

export default App;