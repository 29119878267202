import React, { useState, useEffect, useRef } from 'react';
import styles from './About.module.css';

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('mission');
  const aboutRef = useRef(null);

  const teamMembers = [
    { name: 'Jane Doe', role: 'CEO & Founder', image: 'https://i.pravatar.cc/150?img=1' },
    { name: 'John Smith', role: 'CTO', image: 'https://i.pravatar.cc/150?img=2' },
    { name: 'Emily Johnson', role: 'Lead Developer', image: 'https://i.pravatar.cc/150?img=3' },
    { name: 'Michael Brown', role: 'UX Designer', image: 'https://i.pravatar.cc/150?img=4' },
    { name: 'Sarah Wilson', role: 'Project Manager', image: 'https://i.pravatar.cc/150?img=5' },
    { name: 'David Lee', role: 'Data Scientist', image: 'https://i.pravatar.cc/150?img=6' },
  ];

  const milestones = [
    { year: 2010, event: 'Greytech Solutions founded' },
    { year: 2012, event: 'Launched first enterprise software product' },
    { year: 2015, event: 'Expanded into cloud services' },
    { year: 2018, event: 'Introduced AI and machine learning solutions' },
    { year: 2020, event: 'Achieved ISO 27001 certification' },
    { year: 2022, event: 'Opened international offices in Europe and Asia' },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  return (
    <section ref={aboutRef} className={`${styles.about} ${isVisible ? styles.visible : ''}`}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>About Greytech Solutions</h2>
        <p className={styles.sectionSubtitle}>
          Innovating the future of technology since 2010
        </p>
        <div className={styles.tabContainer}>
          <div className={styles.tabButtons}>
            <button
              className={`${styles.tabButton} ${activeTab === 'mission' ? styles.active : ''}`}
              onClick={() => setActiveTab('mission')}
            >
              Our Mission
            </button>
            <button
              className={`${styles.tabButton} ${activeTab === 'history' ? styles.active : ''}`}
              onClick={() => setActiveTab('history')}
            >
              Our History
            </button>
            <button
              className={`${styles.tabButton} ${activeTab === 'team' ? styles.active : ''}`}
              onClick={() => setActiveTab('team')}
            >
              Our Team
            </button>
          </div>
          <div className={styles.tabContent}>
            {activeTab === 'mission' && (
              <div className={styles.missionContent}>
                <h3>Empowering Businesses Through Technology</h3>
                <p>
                  At Greytech Solutions, our mission is to empower businesses with cutting-edge technology solutions that drive innovation, efficiency, and growth. We are committed to delivering exceptional value to our clients by leveraging our expertise in software development, cloud computing, artificial intelligence, and cybersecurity.
                </p>
                <div className={styles.valueProps}>
                  <div className={styles.valueProp}>
                    <i className="fas fa-lightbulb"></i>
                    <h4>Innovation</h4>
                    <p>Constantly pushing the boundaries of what's possible in tech</p>
                  </div>
                  <div className={styles.valueProp}>
                    <i className="fas fa-users"></i>
                    <h4>Collaboration</h4>
                    <p>Working closely with clients to achieve their goals</p>
                  </div>
                  <div className={styles.valueProp}>
                    <i className="fas fa-shield-alt"></i>
                    <h4>Integrity</h4>
                    <p>Upholding the highest standards of ethics and professionalism</p>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'history' && (
              <div className={styles.historyContent}>
                <h3>Our Journey of Growth and Innovation</h3>
                <div className={styles.timeline}>
                  {milestones.map((milestone, index) => (
                    <div key={index} className={styles.timelineItem}>
                      <div className={styles.timelineMarker}></div>
                      <div className={styles.timelineContent}>
                        <h4>{milestone.year}</h4>
                        <p>{milestone.event}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeTab === 'team' && (
              <div className={styles.teamContent}>
                <h3>Meet the Innovators Behind Greytech Solutions</h3>
                <div className={styles.teamGrid}>
                  {teamMembers.map((member, index) => (
                    <div key={index} className={styles.teamMember}>
                      <div className={styles.memberImage}>
                        <img src={member.image} alt={member.name} />
                      </div>
                      <h4>{member.name}</h4>
                      <p>{member.role}</p>
                      <div className={styles.socialIcons}>
                        <a href="#" className={styles.socialIcon}><i className="fab fa-linkedin"></i></a>
                        <a href="#" className={styles.socialIcon}><i className="fab fa-twitter"></i></a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.backgroundDecoration}>
        <div className={styles.circle}></div>
        <div className={styles.square}></div>
        <div className={styles.triangle}></div>
      </div>
    </section>
  );
};

export default About;