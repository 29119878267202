import React, { useState, useEffect } from 'react';
import './Hero.css';

const Hero = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentWord, setCurrentWord] = useState(0);
  const [currentChar, setCurrentChar] = useState(0);

  const techWords = ['Innovative', 'Scalable', 'Secure', 'Efficient', 'Cutting-edge'];

  useEffect(() => {
    setIsVisible(true);
    const wordInterval = setInterval(() => {
      setCurrentWord((prevWord) => (prevWord + 1) % techWords.length);
      setCurrentChar(0);
    }, 3000);

    return () => clearInterval(wordInterval);
  }, []);

  useEffect(() => {
    if (currentChar < techWords[currentWord].length) {
      const charInterval = setInterval(() => {
        setCurrentChar((prevChar) => prevChar + 1);
      }, 100);

      return () => clearInterval(charInterval);
    }
  }, [currentWord, currentChar]);

  return (
    <section className={`hero ${isVisible ? 'visible' : ''}`}>
      <div className="hero-background">
        <div className="hero-overlay"></div>
        <div className="hero-particles">
          {[...Array(50)].map((_, index) => (
            <div key={index} className="particle"></div>
          ))}
        </div>
      </div>
      <div className="hero-content">
        <h1 className="hero-title">
          Empowering Businesses with
          <span className="tech-word">
            {techWords[currentWord].slice(0, currentChar)}
            <span className="cursor">|</span>
          </span>
          Solutions
        </h1>
        <p className="hero-subtitle">
          Greytech Solutions: Your partner in digital transformation and technological excellence
        </p>
        <div className="hero-cta">
          <button className="cta-button primary">Explore Our Services</button>
          <button className="cta-button secondary">Schedule a Consultation</button>
        </div>
      </div>
      <div className="hero-features">
        <div className="feature-item">
          <i className="feature-icon fas fa-rocket"></i>
          <h3>Rapid Development</h3>
          <p>Accelerate your project timelines with our agile methodologies</p>
        </div>
        <div className="feature-item">
          <i className="feature-icon fas fa-shield-alt"></i>
          <h3>Robust Security</h3>
          <p>Protect your digital assets with our state-of-the-art security measures</p>
        </div>
        <div className="feature-item">
          <i className="feature-icon fas fa-chart-line"></i>
          <h3>Scalable Architecture</h3>
          <p>Build solutions that grow with your business needs</p>
        </div>
        <div className="feature-item">
          <i className="feature-icon fas fa-users"></i>
          <h3>Expert Support</h3>
          <p>24/7 assistance from our team of skilled professionals</p>
        </div>
      </div>
      <div className="hero-scroll-indicator">
        <div className="scroll-text">Scroll to Explore</div>
        <div className="scroll-icon">
          <i className="fas fa-chevron-down"></i>
        </div>
      </div>
    </section>
  );
};

export default Hero;