import React, { useState, useEffect, useRef } from 'react';
import styles from './Testimonials.module.css';

const Testimonials = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const testimonialsRef = useRef(null);

  const testimonials = [
    {
      id: 1,
      name: 'Sarah Johnson',
      company: 'TechCorp Innovations',
      role: 'CTO',
      image: 'https://i.pravatar.cc/150?img=1',
      quote: 'Greytech Solutions transformed our IT infrastructure, boosting efficiency and security. Their expertise in cloud solutions and AI integration has given us a competitive edge in the market.',
      rating: 5,
    },
    {
      id: 2,
      name: 'Michael Chen',
      company: 'Global Finance Group',
      role: 'Head of Digital Transformation',
      image: 'https://i.pravatar.cc/150?img=2',
      quote: 'The blockchain solution implemented by Greytech has revolutionized our transaction processes. Their teams professionalism and technical prowess are truly impressive.',
      rating: 5,
    },
    {
      id: 3,
      name: 'Emily Rodriguez',
      company: 'HealthTech Innovations',
      role: 'CEO',
      image: 'https://i.pravatar.cc/150?img=3',
      quote: 'Greytechs IoT ecosystem has dramatically improved our patient care systems. Their ability to understand and deliver on complex healthcare IT needs is unparalleled.',
      rating: 5,
    },
    {
      id: 4,
      name: 'David Thompson',
      company: 'E-commerce Giants',
      role: 'Director of Operations',
      image: 'https://i.pravatar.cc/150?img=4',
      quote: 'The AI-powered analytics solution from Greytech has provided us with invaluable insights, driving our decision-making process and boosting our ROI significantly.',
      rating: 5,
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (testimonialsRef.current) {
      observer.observe(testimonialsRef.current);
    }

    return () => {
      if (testimonialsRef.current) {
        observer.unobserve(testimonialsRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  const handleDotClick = (index) => {
    setActiveTestimonial(index);
  };

  return (
    <section ref={testimonialsRef} className={`${styles.testimonials} ${isVisible ? styles.visible : ''}`}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>What Our Clients Say</h2>
        <p className={styles.sectionSubtitle}>
          Hear from industry leaders who have experienced the Greytech difference
        </p>
        <div className={styles.testimonialSlider}>
          {testimonials.map((testimonial, index) => (
            <div
              key={testimonial.id}
              className={`${styles.testimonialCard} ${index === activeTestimonial ? styles.active : ''}`}
            >
              <div className={styles.testimonialContent}>
                <div className={styles.quoteIcon}>
                  <i className="fas fa-quote-left"></i>
                </div>
                <p className={styles.quote}>{testimonial.quote}</p>
                <div className={styles.rating}>
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <i key={i} className="fas fa-star"></i>
                  ))}
                </div>
              </div>
              <div className={styles.testimonialAuthor}>
                <div className={styles.authorImage}>
                  <img src={testimonial.image} alt={testimonial.name} />
                </div>
                <div className={styles.authorInfo}>
                  <h3 className={styles.authorName}>{testimonial.name}</h3>
                  <p className={styles.authorRole}>{testimonial.role}</p>
                  <p className={styles.authorCompany}>{testimonial.company}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.testimonialDots}>
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`${styles.dot} ${index === activeTestimonial ? styles.active : ''}`}
              onClick={() => handleDotClick(index)}
            ></button>
          ))}
        </div>
      </div>
      <div className={styles.backgroundDecoration}>
        <div className={styles.circle}></div>
        <div className={styles.square}></div>
        <div className={styles.triangle}></div>
      </div>
    </section>
  );
};

export default Testimonials;