import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import styles from './TestResults.module.css';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const TestResults = ({ answers, timeSpent, totalTime, onReview, questions }) => {
  const navigate = useNavigate();

  const correctAnswers = questions.filter((q, index) => q.correctAnswer === answers[index]).length;
  const incorrectAnswers = questions.length - correctAnswers;
  const unattempted = questions.length - Object.keys(answers).length;

  const pieData = {
    labels: ['Correct', 'Incorrect', 'Unattempted'],
    datasets: [
      {
        data: [correctAnswers, incorrectAnswers, unattempted],
        backgroundColor: ['#4CAF50', '#F44336', '#9E9E9E'],
        hoverBackgroundColor: ['#45a049', '#e53935', '#757575'],
      },
    ],
  };

  const barData = {
    labels: questions.map((_, index) => `Q${index + 1}`),
    datasets: [
      {
        label: 'Time Spent (seconds)',
        data: questions.map((_, index) => timeSpent[index] || 0),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Time Spent per Question',
      },
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div className={styles.resultsContainer}>
      <h1 className={styles.title}>Test Results</h1>
      <div className={styles.summarySection}>
        <h2>Summary</h2>
        <div className={styles.summaryGrid}>
          <div className={styles.summaryItem}>
            <span>Total Questions:</span>
            <span>{questions.length}</span>
          </div>
          <div className={styles.summaryItem}>
            <span>Correct Answers:</span>
            <span>{correctAnswers}</span>
          </div>
          <div className={styles.summaryItem}>
            <span>Incorrect Answers:</span>
            <span>{incorrectAnswers}</span>
          </div>
          <div className={styles.summaryItem}>
            <span>Unattempted:</span>
            <span>{unattempted}</span>
          </div>
          <div className={styles.summaryItem}>
            <span>Score:</span>
            <span>{(correctAnswers / questions.length * 100).toFixed(2)}%</span>
          </div>
          <div className={styles.summaryItem}>
            <span>Total Time:</span>
            <span>{Math.floor(totalTime / 60)}m {totalTime % 60}s</span>
          </div>
        </div>
      </div>
      <div className={styles.chartsSection}>
        <div className={styles.chartContainer}>
          <h2>Answer Distribution</h2>
          <div className={styles.pieChartWrapper}>
            <Pie data={pieData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </div>
        <div className={styles.chartContainer}>
          <h2>Time Spent per Question</h2>
          <div className={styles.barChartWrapper}>
            <Bar options={barOptions} data={barData} />
          </div>
        </div>
      </div>
      <div className={styles.detailedResults}>
        <h2>Detailed Results</h2>
        {questions.map((q, index) => (
          <div key={q.id} className={styles.questionResult}>
            <h3>Question {index + 1}</h3>
            <p>{q.question}</p>
            <p>Your Answer: <strong>{answers[index] || 'Unattempted'}</strong></p>
            <p>Correct Answer: <strong>{q.correctAnswer}</strong></p>
            <p>Time Spent: {timeSpent[index] || 0} seconds</p>
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <button onClick={onReview} className={styles.reviewButton}>
          Review Answers
        </button>
        <button onClick={() => navigate('/')} className={styles.homeButton}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default TestResults;