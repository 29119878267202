import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className={`${styles.header} ${isScrolled ? styles.scrolled : ''}`}>
      <div className={styles.headerContainer}>
        <a href="/" className={styles.logo}>
          <span className={styles.logoText}>GS</span>
        </a>
        <nav className={`${styles.nav} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
          <ul className={styles.navList}>
            <li className={styles.navItem}><a href="/" className={styles.navLink}>Home</a></li>
            <li className={styles.navItem}><a href="/about" className={styles.navLink}>About</a></li>
            <li className={styles.navItem}><a href="/services" className={styles.navLink}>Services</a></li>
            <li className={styles.navItem}><a href="/contact" className={styles.navLink}>Contact</a></li>
            <li className={styles.navItem}><a href="/test" className={styles.navLink}>Take Test</a></li>
          </ul>
        </nav>
        <div className={styles.headerActions}>
          <a href="/test" className={styles.ctaButton}>Take Test</a>
          <button className={styles.mobileMenuToggle} onClick={toggleMobileMenu} aria-label="Toggle mobile menu">
            <span className={styles.hamburgerIcon}></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;