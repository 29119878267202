import React, { useState, useEffect } from 'react';
import styles from './Services.module.css';

const Services = () => {
  const [activeService, setActiveService] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    const servicesSection = document.querySelector(`.${styles.services}`);
    if (servicesSection) {
      observer.observe(servicesSection);
    }

    return () => {
      if (servicesSection) {
        observer.unobserve(servicesSection);
      }
    };
  }, []);

  const services = [
    {
      id: 1,
      title: 'Custom Software Development',
      icon: 'fas fa-code',
      description: 'Tailored software solutions to meet your unique business needs and drive digital transformation.',
      features: ['Agile development', 'Cross-platform compatibility', 'Scalable architecture', 'User-centric design'],
    },
    {
      id: 2,
      title: 'Cloud Solutions',
      icon: 'fas fa-cloud',
      description: 'Harness the power of cloud computing to enhance scalability, security, and accessibility.',
      features: ['Cloud migration', 'Hybrid cloud setup', 'Cloud-native development', 'Serverless architecture'],
    },
    {
      id: 3,
      title: 'Cybersecurity',
      icon: 'fas fa-shield-alt',
      description: 'Protect your digital assets with our comprehensive cybersecurity services and solutions.',
      features: ['Threat detection', 'Penetration testing', 'Security audits', 'Incident response planning'],
    },
    {
      id: 4,
      title: 'AI & Machine Learning',
      icon: 'fas fa-brain',
      description: 'Leverage the power of AI and ML to gain insights, automate processes, and drive innovation.',
      features: ['Predictive analytics', 'Natural language processing', 'Computer vision', 'Automated decision-making'],
    },
    {
      id: 5,
      title: 'IoT Solutions',
      icon: 'fas fa-network-wired',
      description: 'Connect and optimize your devices with our cutting-edge IoT solutions and expertise.',
      features: ['Device management', 'Data analytics', 'Edge computing', 'IoT security'],
    },
    {
      id: 6,
      title: 'Blockchain Development',
      icon: 'fas fa-link',
      description: 'Implement secure and transparent blockchain solutions for various business applications.',
      features: ['Smart contracts', 'Decentralized apps (DApps)', 'Cryptocurrency integration', 'Supply chain tracking'],
    },
  ];

  return (
    <section className={`${styles.services} ${isVisible ? styles.visible : ''}`}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>Our Services</h2>
        <p className={styles.sectionSubtitle}>
          Empowering your business with cutting-edge technology solutions
        </p>
        <div className={styles.serviceGrid}>
          {services.map((service) => (
            <div
              key={service.id}
              className={`${styles.serviceCard} ${activeService === service.id ? styles.active : ''}`}
              onMouseEnter={() => setActiveService(service.id)}
              onMouseLeave={() => setActiveService(null)}
            >
              <div className={styles.serviceIcon}>
                <i className={service.icon}></i>
              </div>
              <h3 className={styles.serviceTitle}>{service.title}</h3>
              <p className={styles.serviceDescription}>{service.description}</p>
              <ul className={styles.serviceFeatures}>
                {service.features.map((feature, index) => (
                  <li key={index} className={styles.featureItem}>
                    <i className="fas fa-check"></i>
                    {feature}
                  </li>
                ))}
              </ul>
              <button className={styles.learnMoreBtn}>Learn More</button>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.backgroundDecoration}>
        <div className={styles.circle}></div>
        <div className={styles.square}></div>
        <div className={styles.triangle}></div>
      </div>
    </section>
  );
};

export default Services;